import i18n from '@/i18n/index'

export default {
  order_information: [{
    prop: 'shop'
  },{
    prop: 'status',
    span: 4,
    back: true
  }, {
    prop: 'admin_remarks',
    span: 4
  }, {
    prop: 'payment_channel',
    span: 4
  }, {
    prop: 'updated_at',
    span: 4
  }, {
    prop: 'paid_at',
    span: 4
  }],
  rest_information: [
    {
      prop: 'name'
    }, {
      prop: 'rest_type_name'
    }, {
      prop: 'start_date'
    }, {
      prop: 'end_date'
    }, {
      prop: 'room_count'
    }, {
      prop: 'adult_count'
    }, {
      prop: 'child_count'
    }
  ],
  customer_information: [{
    prop: 'customer_id'
  }, {
    prop: 'customer_name'
  }, {
    prop: 'customer_phone'
  },{
    prop: 'nickname'
  }],
  addonTableDataViewItem: [{
    prop: 'rest_name',
    label: i18n.t('form.rest.rest'),
    Object: 'value'
  }, {
    prop: 'name',
    label: i18n.t('table.order.addons'),
    Object: 'value'
    // width: '40'
  }, {
    prop: 'price',
    label: i18n.t('form.rest.price'),
    width: '100',
    Object: 'value'
  }, {
    prop: 'count',
    label: i18n.t('form.common.count'),
    Object: 'value'
    // width: '100'
  }],
  roomTableDataViewItem: [{
    prop: 'rest_name',
    label: i18n.t('form.rest.rest'),
    Object: 'value'
  }, {
    prop: 'rest_type_name',
    label: i18n.t('form.rest.restType'),
    Object: 'value'
    // width: '40'
  }, {
    prop: 'price',
    label: i18n.t('form.rest.price'),
    width: '100',
    Object: 'value'
  }, {
    prop: 'name',
    label: i18n.t('form.rest.restRoom'),
    Object: 'value'
    // width: '100'
  }]
}
