<template>
  <div class="managerUserOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.orderInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table
                tableType="object"
                :custSpan="6"
                :data="custName.order_information" 
                :dataItem="order_information"
                @forwardToPage="forwardToPage"
              >
              </custome-table>
            </el-card>
            </el-col>
          </el-row>
          <el-row class="margintop20" :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('table.order.bookingInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.rest_info" :dataItem="rest_information"></custome-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.custInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table tableType="object" :custSpan="8" :data="custName.customer_info" :dataItem="customer_information"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
       <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('table.order.roomInfo')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="roomTableDataViewItem" :operateVisible="false" :tableData="custName.rest_rooms" :operationColWidth="60" @operate="handleClick" @forwardToPage="handleOrderItemsTableForwardToPage"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('table.order.addons')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="addonTableDataViewItem" :operateVisible="false" :tableData="custName.rest_addons" :operationColWidth="60" @operate="handleClick" @forwardToPage="handleOrderItemsTableForwardToPage"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
    <el-dialog
      :title="$t('form.order.romoveProduct')"
      :visible.sync="dialog.delOderItem.visible"
      width="500px">
      <div class="del-oder-item-dialog-body">
        <div class="heder">
          <el-avatar class="avatar" shape="square" :size="80" fit="cover" :src="dialog.delOderItem.data && dialog.delOderItem.data.image.value"></el-avatar>
          <div class="info-box">
            <div class="title">{{ $t('form.order.productName') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.shop_item.value.clickables[0].name }}</div>
            <div class="title">{{ $t('form.order.sku') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.sku.value }}</div>
          </div>
        </div>
        <div class="notice">{{ $t('form.order.removeProducNotice') }}</div>
        <el-form ref="delOderItemDialogForm" :model="dialog.delOderItem.formData" :rules="dialog.delOderItem.formDataRules" label-width="80px" label-position="left">
          <el-form-item :label="$t('form.order.currentCount')">{{ dialog.delOderItem.data && dialog.delOderItem.data.count.value }}</el-form-item>
          <el-form-item :label="$t('form.order.romveCount')" prop="quantity">
            <el-input-number v-model="dialog.delOderItem.formData.quantity" :min="1" :max="dialog.delOderItem.data && dialog.delOderItem.data.count.value"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="handleDelOderItemDialogCancelClick">{{ $t('button.cancelbig') }}</el-button>
        <el-button type="primary" @click="handleDelOderItemDialogOkClick">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getRestOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import request from '@/utils/request'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {
        show: true
      },
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      contact_information: jsonData.contact_information,
      order_information: jsonData.order_information,
      rest_information: jsonData.rest_information,
      customer_information: jsonData.customer_information,
      historyTableItem: history.historyTableItem,
      roomTableDataViewItem: jsonData.roomTableDataViewItem,
      addonTableDataViewItem: jsonData.addonTableDataViewItem,
      logisticViewItem: [],
      // dialogData: [],
      // batchData: [],
      // dialogVisible: false,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    // const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
    // if (to.name === 'managerProductEdit') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'edit', obj: dataOperate.obj }))
    // }
    // if (to.name === 'managerProductCopy') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'copy', obj: dataOperate.obj }))
    // }
    // if (!(to.name === 'managerProductEdit' || to.name === 'managerProductCopy')) {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    // }
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))

    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getRestOrderDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.rest_payment
        // Object.assign(this.titleObject, { title: '', data: '', show: false })
        Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: this.custName.basic_information.payment_no.value || '' })
      }
      this.$store.state.app.loading.close()
      this.getDetail()
    },
    getDetail () {
      const info = JSON.parse(JSON.stringify(this.custName.basic_information))
      const {
        rest_name,
        rest_type_name,
        start_date,
        end_date,
        customer_name,
        customer_phone,
        status,
        updated_at,
        shop,
        admin_remarks,
        adult_count,
        child_count
      } = info
      if (info && info.rest) {
        this.custName.rest_info = Object.assign({
          rest_type_name, 
          start_date, 
          end_date,
          adult_count,
          child_count,
          room_count: {
            name: this.$t('table.order.roomsCount'),
            value: '1'
          }
        }, info.rest)
      }
      this.custName.order_information = {
        payment_channel: {
          name: this.$t('form.order.paymentMethod'),
          value: ''
        },
        paid_at: {
          name: this.$t('table.order.payTime'),
          value: ''
        },
        admin_remarks,
        status,
        shop,
        updated_at
      }
      this.custName.customer_info = {
        customer_id: {
          name: 'ID',
          value: ''
        },
        customer_name,
        customer_phone
      }
      this.custName.rest_addons = info.rest_addons
      if (info.rest_type) {
        const {rest_room, price} = info.rest_type
        this.custName.rest_rooms = [
          Object.assign({
            rest_name: {
              name: this.$i18n.locale == 'zh-CN' ? info.rest.name.name : info.rest.name_en.name,
              value: this.$i18n.locale == 'zh-CN' ? info.rest.name.value : info.rest.name_en.value
            },
            rest_type_name,
            price
          }, rest_room)
        ]
      }
      
      this.custName.amout_info = {

      }
      // if (this.custName && this.custName.ticket_copies && this.custName.ticket_copies.length > 0) {
      //   Object.keys(this.custName.ticket_copies[0]).forEach(item => {
      //     const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
      //     if (index !== -1) this.tableDataViewItem[index].label = this.custName.ticket_copies[0][item].name
      //   })
      // }
      // if (this.custName && this.custName.logistic_information && Object.keys(this.custName.logistic_information).length > 0) {
      //   Object.keys(this.custName.logistic_information).forEach(key => {
      //     this.logisticViewItem.push({
      //       prop: key
      //     })
      //   })
      // }
      //  获取历史操作
      getHistoryList({ item_type: 'RestPayment', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },

    async dialogSearch () {
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data, extraType) {
      if (extraType === 'del') {
        if (data.count.value > 0) {
          this.dialog.delOderItem.formData.quantity = 1
          this.dialog.delOderItem.data = data
          this.dialog.delOderItem.visible = true
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('form.order.productCountLessThanOne')
          })
        }
      }
    },
    handleDelOderItemDialogCancelClick () {
      this.dialog.delOderItem.visible = false
    },
    handleDelOderItemDialogOkClick () {
      this.$refs.delOderItemDialogForm.validate((valid) => {
        if (valid) {
          const data = this.dialog.delOderItem.data
          const requestOption = data.member_actions.custom_actions[0]
          this.$store.state.app.loading = this.$loading(loading)
          request({
            method: requestOption.method,
            url: `/cms/${requestOption.path}`,
            data: {
              [requestOption.extra_params[0].key]: this.dialog.delOderItem.formData.quantity
            }
          }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.operateSuccess')
            })
            this.dialog.delOderItem.visible = false
            this.init()
          }).catch(() => this.$store.state.app.loading.close())
        }
      })
    },
    forwardToPage (data) {
      if (data.model_name && data.model_name === 'shop' && data.id) {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      if (name === 'rest_name') {
        this.$router.push({
          name: 'restsManagerRestDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'rest_type_name') {
        this.$router.push({
          name: 'restsManagerCategoryDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .customer_detail_title {
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 700;
  }
  .customer_detail_data {
    font-size: 14px;
    color: #40586F;
  }
  .customer_detail {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 16px;
      font-weight: 700;
    }
  }
  .customer_detail_number {
    display: flex;
    justify-content: space-between;
  }
  .customer_detail_amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
